import React from "react"
import PropTypes from "prop-types"

// Components
import { Helmet } from "react-helmet"
import { graphql } from "gatsby"
import Work from "components/Work"
import Layout from "components/Layout"
import SEO from "components/seo"

// util
import getDisciplinesFromQuery from "../../util/getDisciplinesFromQuery"

const WorkPage = ({
  location: { search = "", pathname = "/work" },
  data: {
    disciplinesGroup: { group },
    allPosts = [],
    site: {
      siteMetadata: { title },
    },
  },
}) => {
  const posts = allPosts.nodes.map(post => {
    const {
      frontmatter: {
        title,
        description,
        author,
        heroImage,
        previewImage,
        date,
        disciplines,
      },
      html,
      fields: { slug },
    } = post
    return {
      slug,
      title,
      description,
      author,
      heroImage,
      previewImage,
      date,
      disciplines,
      html,
    }
  })
  return (
    <div>
      <Helmet title={title} />
      <Layout backgroundColor={"white"} navColor={"black"}>
        <SEO
          title="Thesis | Real people make real work"
          keywords={["thesis agency", "portland agency", "marketing agency"]}
          description="Thesis began as an email marketing agency (eROI) but has evolved into a full-service digital agency—creative, marketing, strategy. All the digital things. Learn more."
        />
        <Work
          group={group}
          posts={posts}
          selectedDisciplines={getDisciplinesFromQuery(search)}
          pathname={pathname}
        />
      </Layout>
    </div>
  )
}

WorkPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      group: PropTypes.arrayOf(
        PropTypes.shape({
          fieldValue: PropTypes.string.isRequired,
          totalCount: PropTypes.number.isRequired,
        }).isRequired
      ),
    }),
    allPosts: PropTypes.shape({
      nodes: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          html: PropTypes.string.isRequired,
          frontmatter: PropTypes.object.isRequired,
        })
      ),
    }),
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        title: PropTypes.string.isRequired,
      }),
    }),
  }),
}

export default WorkPage

// prettier-ignore
export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    disciplinesGroup: allMarkdownRemark(
      limit: 2000
      filter: { fileAbsolutePath: { regex: "/content\\/thought/" } }
    ) {
      group(field: frontmatter___disciplines) {
        fieldValue
        totalCount
      }
    }
    allPosts: allMarkdownRemark(
      limit: 2000
      sort: { fields: frontmatter___date, order: DESC }
      filter: { fileAbsolutePath: { regex: "/content\\/thought/" } }
    ) {
      nodes {
        frontmatter {
          heroImage
          previewImage
          title
          description
          author
          date
          disciplines
        }
        html
        fields {
          slug
        }
      }
    }
  }
`
